/* const Label = styled('label', {
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'clip',
    position: 'relative',
    textAlign: 'center',
    gridRow: 1,
    center: 'row',
    variants: {
        isToday: {
            true: {
                // highlight: highlight,
                color: '$primary11'
            }
        },
    }
})

const Container = styled('section', {
    gridArea: 'daysOfWeek',
    display: 'grid',
    gridAutoColumns: '1fr',
    boxSizing: 'border-box',
    position: 'sticky',
    width: 'stretch',
    top: 0,
    // margin: 3,
    height: (HEIGHT_OF_TIMEGRID_CELL_IN_REM * 2) + "rem",
    // backgroundColor: '$gray1',
    zIndex: 5, // has to be at least 10 so rnd will go under
    border: '$borderThin',
    borderRight: 'none',
    background: 'inherit'
}) */


.container {
    grid-area: daysOfWeek;
    display: grid;
    grid-auto-columns: 1fr;
    box-sizing: border-box;
    position: sticky;
    width: stretch;
    top: 0;
    height: 1.50rem;
    /* height: calc(var(--height-of-timegrid-cell-mobile) * 2); */
    z-index: 5;
    border: 1px solid #e0e0e0;
    border-right: none;
    background: inherit;

    /* @media screen and (min-width: 600px) {
        height: calc(var(--height-of-timegrid-cell) * 2);
    } */
}

.label {
    min-width: 0;
    overflow: hidden;
    text-overflow: clip;
    position: relative;
    text-align: center;
    grid-row: 1;
    align-items: center;
}