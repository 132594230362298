.gsiMaterialButton {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
  /* margin-bottom: 10px; */
}

.gsiMaterialButton .gsiMaterialButtonIcon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsiMaterialButton .gsiMaterialButtonContentWrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsiMaterialButton .gsiMaterialButtonContents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsiMaterialButton .gsiMaterialButtonState {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsiMaterialButton:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsiMaterialButton:disabled .gsiMaterialButtonContents {
  opacity: 38%;
}

.gsiMaterialButton:disabled .gsiMaterialButtonIcon {
  opacity: 38%;
}

.gsiMaterialButton:not(:disabled):active .gsiMaterialButtonState,
.gsiMaterialButton:not(:disabled):focus .gsiMaterialButtonState {
  background-color: #303030;
  opacity: 12%;
}

.gsiMaterialButton:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(157, 167, 176, 0.3), 0 1px 3px 1px rgba(157, 167, 176, .15);
  box-shadow: 0 1px 2px 0 rgba(157, 167, 176, .30), 0 1px 3px 1px rgba(157, 167, 176, .15);
}

.gsiMaterialButton:not(:disabled):hover .gsiMaterialButtonState {
  background-color: #303030;
  opacity: 8%;
}

.loginButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  background-color: #24292e;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: white;
  text-decoration: none;
  box-sizing: border-box;
  padding-right: 12px;
  border-radius: 4px;
}

.loginButton .iconWrapper {
  margin: 1px;
  display: flex;
  align-items: center;
  /* padding-top: 5px; */
  min-height: 40px;
  padding-left: 3px;
}

.loginButton:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(157, 167, 176, 0.3), 0 1px 3px 1px rgba(157, 167, 176, .15);
  box-shadow: 0 1px 2px 0 rgba(157, 167, 176, .30), 0 1px 3px 1px rgba(157, 167, 176, .15);
  opacity: 0.85;
}

#or {
  position: relative;
  width: 300px;
  height: 30px;

  line-height: 30px;
  text-align: center;
}

#or::before,
#or::after {
  position: absolute;
  width: 130px;
  height: 1px;

  top: 14px;

  background-color: #aaa;

  content: '';
}

#or::before {
  left: 0;
}

#or::after {
  right: 0;
}

.cardHeader {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-block: 10px 20px;
  font-family: Inter, "Open Sans", sans-serif;
  text-decoration: underline;
  /* 'Courier New', Courier, monospace; */
  /* TODO font family for header */
}