.chat-textarea {
  .rt-TextAreaInput {
    height: 50px;
    @apply py-3 px-4 pr-24;

    img {
      @apply max-w-md max-md:max-w-full;
    }

    table {
      table-layout: fixed;
      width: 100%;
    }
  }
}

.chart-side-bar {
  @apply overflow-hidden z-10;
  //  w-64

  background-color: var(--color-background);
  transition: 0.5s;

  // width: 256px;
  // min-width: 256px;
  min-width: max-content;

  @apply max-md:absolute max-md:top-0 max-md:bottom-0 max-md:border-none max-md:w-0;

  .rt-ScrollAreaViewport>div {
    display: block;
  }

  >div {
    border-right: 1px solid var(--gray-a4);
  }

  &.show {
    @apply w-64;
  }
}

.bg-token-surface-primary {
  @apply flex gap-2 select-none items-center rounded-2xl px-3 py-2 duration-100;
  background-color: var(--accent-a2);

  &:hover {
    background-color: var(--accent-a3);
  }
}

.bg-token-surface-primary-solid {
  @apply flex gap-2 select-none items-center rounded-2xl px-3 py-2 duration-100;
  background-color: var(--accent-2);

  &:hover {
    background-color: var(--accent-3);
  }
}

.bg-token-surface {
  @apply flex gap-2 select-none items-center rounded-2xl px-3 py-2 duration-100 justify-between;

  &.active {
    background-color: var(--accent-a4);
  }

  &:hover {
    background-color: var(--accent-a3);
  }
}

.userMessage {
  table {
    @apply border-collapse border border-slate-400;

    th,
    td {
      @apply border border-slate-300;
    }
  }
}

.gradient-box {
  // display: flex;
  // align-items: center;
  //width: 50vw;
  // width: 90%;
  // margin: auto;
  // max-width: 22em;

  position: relative;
  // padding: 30% 2em;
  box-sizing: border-box;

  $border: 3px;
  // color: #FFF;
  // background: #000;
  background-clip: padding-box;
  /* !importanté */
  border: solid $border transparent;
  /* !importanté */
  border-radius: 1em;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border;
    /* !importanté */
    border-radius: inherit;
    /* !importanté */
    background: linear-gradient(to right, var(--blue-7), var(--grass-6));
  }
}

.gradient-box-wrap {
  position: relative;
  background: linear-gradient(to right, var(--blue-7), var(--grass-6));
  padding: 3px;
}

.gradient-box-warm-wrap {
  position: relative;
  background: linear-gradient(to right, var(--red-8), var(--orange-5));
  padding: 3px;
}

.gradient-box-warm {
  // display: flex;
  // align-items: center;
  //width: 50vw;
  // width: 90%;
  // margin: auto;
  // max-width: 22em;

  position: relative;
  // padding: 30% 2em;
  box-sizing: border-box;

  $border: 3px;
  // color: #FFF;
  // background: #000;
  background-clip: padding-box;
  /* !importanté */
  border: solid $border transparent;
  /* !importanté */
  border-radius: 1em;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border;
    /* !importanté */
    border-radius: inherit;
    /* !importanté */
    background: linear-gradient(to right, var(--red-8), var(--orange-5));
  }
}