@value focus-width: 1.5px;
/* export const ToggleItem = styled(ToggleGroupPrimative.Item, {
    all: 'unset',
    backgroundColor: '$gray1',
    color: '$outline',
    // height: 35,
    // width: 35,
    padding: '0.5rem 0.75rem',
    flex: '1',
    display: 'flex',
    fontSize: '0.7em',
    lineHeight: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 1,
    '&:first-child': { marginLeft: 0, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 },
    '&:last-child': { borderTopRightRadius: 4, borderBottomRightRadius: 4 },
    '&:hover': { backgroundColor: '$primary3' }, // '$primary3'
    '&[data-state=on]': {
        position: 'relative',
        // backgroundColor: '$primary5', color: '$primary11',
        boxShadow: `$focus`
    },
    '&:focus': { position: 'relative', boxShadow: `$focus` },
});
export const ToggleGroupRoot = styled(ToggleGroupPrimative.Root, {
    display: 'inline-flex',
    backgroundColor: '$gray6',
    borderRadius: 4,
    boxShadow: `rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px`,
    // boxShadow: '$boxShadow'
    // boxShadow: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px`,
    // flexBasis: '40%',
    // flex: 1
});

const Flex = styled('div', {
    all: 'unset',
    flexGeneral: 'column'
}) */

.toggleItem {
    all: unset;
    background-color: #f5f5f5;
    color: #000;
    padding: 0.5rem 0.75rem;
    flex: 1;
    display: flex;
    font-size: 0.7em;
    line-height: 1;
    align-items: center;
    justify-content: center;
    margin-left: 1;
}

.toggleItem:first-child {
    margin-left: 0;
    border-top-left-radius: 4;
    border-bottom-left-radius: 4;
}

.toggleItem:last-child {
    border-top-right-radius: 4;
    border-bottom-right-radius: 4;
}

.toggleItem:hover {
    background-color: #f0f0f0;
}

.toggleItem[data-state='on'] {
    position: relative;
    box-shadow: 0 0 0 focus-width #000;
}

.toggleItemOn {
    position: relative;
    box-shadow: 0 0 0 focus-width #000;
}

.toggleItem:focus {
    position: relative;
    box-shadow: 0 0 0 focus-width #000;
}

.toggleGroupRoot {
    display: inline-flex;
    background-color: #f5f5f5;
    border-radius: 4;
    box-shadow: 0 focus-width focus-width rgba(0, 0, 0, 0.25), 0 0 focus-width focus-width rgba(0, 0, 0, 0.13);
}