.votes {
    display: flex;
    flex-wrap: wrap;
    /* width: 10px; */
    font-size: 10px;
    line-height: 100%;
}

.votes>div {
    flex: 1;
    text-align: center;
}

.info {
    /* position: absolute; */
    /* height: 50px; */
    max-width: 175px;
    width: 100%;
    border-radius: max(var(--radius-2), var(--radius-full));
    top: 100%;
    margin-top: -5px;
    padding: 5px;
    padding-top: 10px;
    border: 1px solid var(--accent-a6);
    background-color: var(--accent-a2);
    font-size: small;
    word-wrap: break-word;
    min-width: 0px;
}

/* background-color: lightblue; */
/* .container>div:nth-child(1) {
}

.container>div:nth-child(2) {
    background-color: lightcoral;
}

.container>div:nth-child(3) {
    background-color: var(--green-7);
} */

/* background-color: var(--accent-surface);
    box-shadow: inset 0 0 0 1px var(--accent-a7);
    color: var(--accent-a11); */