@value loader-color: var(--accent-7);

/* HTML: <div class="loader"></div> */
.loader {
    width: 60px;
    display: flex;
    align-items: flex-start;
    aspect-ratio: 1;
}

.loader:before,
.loader:after {
    content: "";
    flex: 1;
    aspect-ratio: 1;
    --g: conic-gradient(from -90deg at 10px 10px, loader-color 90deg, #0000 0);
    background: var(--g), var(--g), var(--g);
    filter: drop-shadow(30px 30px 0 loader-color);
    animation: l20 1s infinite;
}

.loader:after {
    transform: scaleX(-1);
}

@keyframes l20 {
    0% {
        background-position: 0 0, 10px 10px, 20px 20px
    }

    33% {
        background-position: 10px 10px
    }

    66% {
        background-position: 0 20px, 10px 10px, 20px 0
    }

    100% {
        background-position: 0 0, 10px 10px, 20px 20px
    }
}