@value border-color: var(--accent-12);
/* const Container = styled('div', {
    gridArea: 'calendar',
    background: 'inherit',
    // overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 10,
    position: 'relative',
    width: 'stretch',
    boxSizing: 'border-box',

    minWidth: 0,
    minHeight: 0,
    backgroundColor: 'inherit'
})

const Calendar = styled('div', {
    background: 'inherit',
    overflowY: 'scroll',
    position: 'relative',
    display: 'grid',
    gridTemplateAreas: `
    '.... daysOfWeek'
    'hours grid'`,
    gridTemplateColumns: `max-content 1fr`,
    gridTemplateRows: '1fr',
    boxSizing: 'border-box',
    minWidth: 0,
    minHeight: 0,
    // size: '100%',
    flex: 1,
    borderRight: '$border',
})

const Grid = styled('div', {
    display: 'grid',
    width: 'stretch',
    // gridAutoRows: '1.5rem', // 'repeat(48, 1.5rem)
    gridTemplateRows: `repeat(${48}, ${HEIGHT_OF_TIMEGRID_CELL_IN_REM}rem)`,
    gridAutoFlow: 'column',
    gridAutoColumns: '0.5rem',
    position: 'relative',
    gridArea: 'grid',
    boxSizing: 'border-box',
    background: '$timegridbg',
})

const Cell = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',
    // borderBottom: '$borderThin',
    borderLeft: '$borderThin',

    variants: {
        value: {
            available: {
                backgroundColor: 'green'
            },
            unavailable: {
                backgroundColor: 'red'
            },
            neutral: {
                backgroundColor: 'white'
            }
        },
        isOver: {
            true: {
                backgroundColor: '$timegridCellHover'
            }
        },
        isAlmostStartOfHour: {
            true: {
                borderBottom: '$borderThin',
            }
        },
        isAlmostMiddleOfHour: {
            true: {
                borderBottomStyle: 'dashed',
            }
        },
        isSameDayOfWeek: { //unused, commented out in compound variants cuz not sure how to make dayview not brighten with dayview
            true: {}
        },

    },
})

const ColorRatio = styled('div', {
    display: 'flex',
    height: 20,
    gap: 1,
    background: '$loContrast',
    width: 100,
    border: '$border',
    left: 0,
    right: 0,
    margin: 'auto',
}) */

.container {
    grid-area: calendar;
    background: inherit;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    position: relative;
    width: stretch;
    box-sizing: border-box;
    min-width: 0;
    min-height: 0;
    background-color: inherit;
    max-height: 100%;
}

.calendar {
    background: inherit;
    overflow-y: scroll;
    position: relative;
    display: grid;
    grid-template-areas: '.... daysOfWeek' 'hours grid';
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1fr;
    box-sizing: border-box;
    min-width: 0;
    min-height: 0;
    flex: 1;
    border-right: 1px solid border-color;
}

.grid {
    display: grid;
    width: stretch;
    /* --height-of-timegrid-cell-mobile: 1.5rem; */
    /* grid-template-rows: repeat(28, var(--height-of-timegrid-cell-mobile)); */
    grid-auto-flow: column;
    grid-auto-columns: 0.5rem;
    position: relative;
    grid-area: grid;
    box-sizing: border-box;
    background: #f5f5f5;
    touch-action: none;

    /* --height-of-timegrid-cell: 0.5rem; */

    /* @media screen and (min-width: 600px) {
        --height-of-timegrid-cell: 0.75rem;
        grid-template-rows: repeat(48, var(--height-of-timegrid-cell));
    } */
}

.colorRatio {
    display: flex;
    height: 20px;
    gap: 1px;
    background: var(--color-background);
    width: 100px;
    border: 1px solid border-color;
    left: 0;
    right: 0;
    margin: auto;
}

/* Path: features/simple-calendar/DayOfWeekLabels.module.css */