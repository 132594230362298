/* const Container = styled('section', {
    gridArea: 'hours',
    display: 'grid',
    gridAutoRows: HEIGHT_OF_TIMEGRID_CELL_IN_REM + 'rem',
    // gridTemplateRows: 'repeat(48, 1.5rem)',
    // gridAutoFlow: 'column',
    width: 'max-content',
    variants: {
        color: {
            true: {
                // backgroundColor: '$timegridbg'
            }
        }
    }
})

const Label = styled('label', {
    boxSizing: 'border-box',
    // padding: "0.2rem 0 0 0.2rem",
    paddingRight: 5,
    flex: '1',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    // fontSize: '0.8rem',
    fontSize: 12,

    variants: {
        isLast: {
            true: {
                borderBottom: '$borderThin'
                //   borderBottomStyle: 'solid',
            },
        },
    }
}) */

/* Convert stitches above to css modules */
.container {
    grid-area: hours;
    display: grid;
    width: max-content;
    grid-auto-rows: var(--height-of-timegrid-cell-mobile);
    font-size: 0.5rem;

    @media screen and (min-width: 600px) {
        grid-auto-rows: var(--height-of-timegrid-cell);
        font-size: 1rem;
    }
}

.label {
    box-sizing: border-box;
    padding-right: 5px;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 12px;
    grid-row: span 2;
}

.label:last-child {
    border-bottom: 1px solid #e0e0e0;
}